html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}

div#wrapper {
  position: fixed; 
  top: 0px; 
  height: 100%; 
  width: 100%; 
  overflow-y: hidden;
}

#wrapper2 {
  position: fixed;
  top: 50px;
  bottom: 0px;
  width: 100%; 
  overflow-y: scroll;
  z-index: 110;
  pointer-events: none;
}


h3 {
  font-size: 20px;
}

#loader {
  background-image: url("asterholma1.jpg");
  background-size: auto 100%;
  background-repeat: repeat;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #ffffff;
  opacity: 1;
  font-family: sans-serif;
}

#loaderbox {
  display: block;
  width: 100%;
  position: absolute;
  top: 50px;
}

#loaderheader {
  top: 50px;
  width: 100%;
  display: block;
}

#loaderheader img {
  display: block;
  margin: auto;
}

#loadercontent {
  text-align: center;
  margin-left: auto;
  margin-right: auto; 
  width: 100%;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.5);
}

#loadercontent #h1 {
  font-size: 24px;
}

.fmodal {
  position: fixed;
  top: 0px;
  width: 100%;
  text-align: center;
  bottom: 0px;
  padding: 10px;
  background-color: rgba(0,0,0, 0.6);
  z-index: 110;
  overflow-y: scroll;
}

.fmodalcontent {
  max-width: 630px;
  min-height: 100%;
  margin: auto;
  text-align: left;
  background-color: white;
  padding: 15px;
}

#timetables .fmodalcontent {
  max-width: 730px;
}

.fmodalheader {
  position: relative;
  margin-bottom: 20px;
}

.fmodalheader .closeInfoButton {
  top: 0px;
  right: 0px;
}

.timetablescontent {
  max-width: 730px;
}

.timetablelink {
  overflow-x: scroll;
}

.timetable {
  max-width: 700px;
}

#infopage {
  font-family: 'Roboto', sans-serif;
  top: 50px;
  pointer-events: auto;
}

div#infopagecontent {
  position: relative;
  max-width:600px;
  min-height: 100%;
  margin: auto;
  text-align: left;
  background-color: white;
  padding: 15px;
}

#infopage table {
  width: 100%;
}

#infopage td, #infopage th {
  min-width: 50px;
  padding: 5px;
}

#infopage h3, #infopage h4 {
  margin-top: 30px;
}

#followbox {
  margin-top: 30px;
}

#followbox ul {
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}

#followbox li {
  margin-bottom: 5px;
  vertical-align: middle;
}

#followbox li i {
  font-size: 1.5em;
  margin-right: 20px;
  margin-top: 5px;
  vertical-align: middle;
}

#followbox li span {
  vertical-align: middle;
}

#licensebox {
  border-top: solid 1px;
  border-color: #bbb;
  margin-top: 40px;
  padding-top: 10px;
}

.fbcolor {
  color: #3B5998;
}

.twittercolor {
  color: #00aced;
}

.instagramcolor {
  color: #e4405f;
}
.infosection h1, .infosection h2 {
  margin-bottom: 30px;
}

#marinetrafficbutton {
  width: 100%;
}
.closeInfoButtonHolder {
  position: sticky;
  top: 0;
  max-width: 630px;
  margin: 0 auto 0 auto;
  height: 0;
  z-index: 1;
}

#timetables .closeInfoButtonHolder {
  max-width: 730px;
}

.closeInfoButton {
  background-color: #eeeeee;
  font-size: 20px;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #000;
  z-index: 10;
}

.closeInfoButton {
  position: absolute;
  top: 20px;
  right: 20px;
}

.closeInfoButtonPlaceHolder {
  float: right;
  margin-left: 5px;
  width: 40px;
  height: 40px;
}

.closeInfoButton.fix {
  position: fixed;
  top: 70px;
  right: 21px;
  box-shadow: 0 2px 2px 0 rgba(220, 220, 220, 0.5);
}

.closeInfoButtonHolder .closeInfoButton {
  margin-top: 0px;
  top: 15px;
  right: 15px;
}

.scrollIndicator {
  height: 25px;
  width: 100%;
  display: none;
  z-index: 130;
  background-color: #ecececad;
  position: fixed;
  bottom: 0px;
  padding-left: 50%;
  pointer-events: none;
}

.scrollIndicator.can-scroll {
  display: block;
}

.scrollIndicator i {
  position: relative;
  font-size: 40px;
  left: -20px;
  top: -6px;
  color: #822928;
}

.map div {
  text-shadow:
  -2px -2px 1px rgba(255, 255, 255, 0.7),
  2px -2px 1px rgba(255, 255, 255, 0.7),
  -2px 2px 1px rgba(255, 255, 255, 0.7),
  2px 2px 1px rgba(255, 255, 255, 0.7),
  -2px 0px 1px rgba(255, 255, 255, 0.7),
  2px 0px 1px rgba(255, 255, 255, 0.7),
  0px -2px 1px rgba(255, 255, 255, 0.7),
  0px 2px 1px rgba(255, 255, 255, 0.7);
}

.map.satellite div {
  text-shadow:
  -1px -1px 0px rgba(0, 0, 0, 0.3),
  1px -1px 0px rgba(0, 0, 0, 0.3),
  -1px 1px 0px rgba(0, 0, 0, 0.3),
  1px 1px 0px rgba(0, 0, 0, 0.3),
  -1px 0px 0px rgba(0, 0, 0, 0.3),
  1px 0px 0px rgba(0, 0, 0, 0.3),
  0px -1px 0px rgba(0, 0, 0, 0.3),
  0px 1px 0px rgba(0, 0, 0, 0.3);
}

#map.hide {
  opacity: 0;
}

#map .gm-iv-address div {
  text-shadow: none;
}
#map .gm-style-cc div {
  text-shadow: none;
}
#map .gm-style-iw div {
  text-shadow: none;
}

.map .txtol {
  position: absolute;
  white-space: nowrap;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -moz-user-select: none;
}

.map .area {
  color: #202030;
  opacity: 0.6;
}

.map.satellite .area {
  color: #ffffff;
}

.map .area.bg {
  background-color: rgba(255, 255, 255, 0.5);
}

.map.satellite .area.bg {
  background-color: transparent;
}

.map .area.province {
  font-weight: bold;
  font-size: 2em;
}

.map .area.mun1 {
  font-weight: bold;
  font-size: 1.8em;
}

.map .area.mun2 {
  font-weight: bold;
  font-size: 1.4em;
}

.map .area.island1 {
  font-weight: normal;
  font-size: 1em;
  font-family: 'Trebuchet MS';
  color: #101010;
  opacity: 0.8;
}

.map.satellite .area.island1 {
  color: #ddd;
}

.map .pier {
  color: #002080;
  opacity: 0.9;
  cursor: pointer;
}

.map.satellite .pier {
  color: #6ac1fc;
}
.map .pier-1 {
  font-size: 1.2em;
  font-weight: 600;
}

.map .pier-2 {
  font-size: 1.1em;
  font-weight: 600;
}

.map .pier-3 {
  font-size: 1.1em;
  font-weight: 500;
}

.map .pier-4 {
  font-size: 1em;
}

.map .vessel {
  color: #800080;
  font-size: 1.1em;
  font-weight: 400;
  background-color: rgba(255,255,255,0.2);
  padding: 1px;
  z-index: 11;
}

.map.satellite .vessel {
  color: #80b0a0;
  background-color: initial;
}

.map .vessel.stopped {
  font-size: 0.7em;
  font-weight: 600;
  z-index: 10;
}

.map .vessel.old{
  opacity: 0.4;
  z-index: 9;
}

.map .distancebox {
  visibility: hidden;
  background-color:rgba(0, 101, 189, 0.7);
  border-radius: 3px;
  border: 2.5px solid white;
  position: absolute;
  padding: 4px;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
  font-size: 0.9em;
  line-height: 1.1;
  color: #ffffff;
  text-shadow: none;
  user-select: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
}

.map .connectionlabelbox {
  border: 3px solid rgba(7, 5, 165, 0.6);
  border-radius: 4px;
  padding: 3px;
  background: rgba(240, 250, 255, 0.7);
  z-index: 20;
  max-width: 250px;
  white-space: normal;
}

.map .connectionlabelbox .connectionname {
  font-size: 1.4em;
  padding: 3px;
}

.map .connectionlabelbox .connectionspecifier {
  font-size: 1.1em;
  padding: 0px 3px;
}

.map {
  height: 100%;
  width: 100%; 
  transform: scale(1); 
  position: absolute; 
  left: -0px; 
  top: -0px; 
}

.btn:focus, .btn.active:focus, button:focus, select:focus {outline:0;}

.btn-default.active, .btn-default.active:focus {
  background-color: #bbb;
}

.btn-default:focus {
  background-color: #fff;
  border-color: #ccc;
}

.lastrow {
  margin-top: 30px;
}

@media (hover:hover) {
  .btn:hover, .btn.active:hover { 
    background-color: #8de;
  }
}

#bannerModal span {
  background: white;
}
#dont-show-again {
  margin-right: 30px;
  position: relative;
  top: 4px;
}

#dont-show-again input {
  margin-right: 10px;
}

#topbar {
  position: fixed;
  top: 0px;
  height: 50px;
  width: 100%;
  background-color: white;
  border-bottom: solid #aaa 1px;
  z-index: 110;
  box-shadow: 4px 4px grey;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#topbartitle {
  font-size: 22px;
  font-weight: 600;
  color: #444;
  width: 100%;
  text-align: center;
  padding: 10px;
  position: absolute;
  background: transparent;
  pointer-events: none;
}

.topbarbutton {
  float: left;
  max-height: 46px; 
  border: none;
  border-radius: 5px 5px 0 0;
  text-align: left;
  margin-left: 4px;
  margin-top: 2px;
  padding-left: 15px;
  outline: 0;
  font-size: 30px;
  color: #444;
  background: transparent;
  transition: background-color 100ms linear;
}

.topbarbutton.left {
  width: 120px;
}

.topbarbutton .description {
  font-size: 12px;
  padding: 0 0 0 6px;
  text-transform: uppercase;
  transition: font-size 100ms ease-out;
}

.topbarbutton.right .description {
  padding: 0 6px 0 0;
}

.topbarbutton.search {
  width: 135px;
  padding-left: 14px;
}

.topbarbutton.right {
  width: 196px;
  float: right;
  text-align: right;
  margin-left: 0px;
  margin-right: 4px;
  padding-left: 0px;
  padding-right: 15px;
}

.topbarbutton.active {
  background-color: #f2f2f2;
  transition: background-color 100ms linear;
}

.topbarbutton.active .description {
  font-size: 17px;
  transition: font-size 200ms ease-out;
}

@media screen and (max-width: 650px) {
  .topbarbutton.search {
    width: 55px;
  }
  .topbarbutton.search .description {
    display: none;
  }
}

@media screen and (max-width: 512px) {
  .topbarbutton, .topbarbutton.left, .topbarbutton.search, .topbarbutton.right {
    width: 55px;
    margin-left: 0;
    margin-right: 0;
  }
  .topbarbutton .description {
    display: block;
    position: fixed;
    top: 60px;
    left: 30px;
    padding: 5px;
    background-color: rgba(0,0,0,.7);
    color: white;
    border-radius: 0px 7px 7px 7px;
    transition: opacity 500ms linear;
    transition-delay: 1000ms;
  }
  .topbarbutton.search .description {
    top: 60px;
    left: 85px;
    display: block;
  }
  .topbarbutton.right .description {
    top: 60px;
    left: initial;
    padding: 5px;
    right: 30px;
    border-radius: 7px 0px 7px 7px;
  }
  .topbarbutton .description:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-bottom-color: rgba(0,0,0,.7);
    border-top: 0;
    border-left: 0;
    margin-top: -15px;
  }
  .topbarbutton.right .description:after {
    left: initial;
    right: 0%;
    border: 15px solid transparent;
    border-bottom-color: rgba(0,0,0,.7);
    border-top: 0;
    border-right: 0;
    margin-top: -15px;
  }
  .topbarbutton.active .description {
    display: none;
  }
  .topbarbutton .description.hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms linear;
  }
}


.slidedownmenu {
  position: fixed;
  top: 50px;
  width: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 120;
  border-radius: 0 0 10px 10px;
  background-color: white;
  max-height: 0%;
  transition: max-height 100ms linear;
}

.slidedownmenu.open {
  max-height: 90%;
  transition: max-height 200ms linear;
}

#menu {
  left: 0px;
  box-shadow: 2px 2px #ddd;
}

#settings {
  right: 0px;
  box-shadow: -2px 2px #ddd;
}

#searchpanel {
  top: 55px;
  left: 20px;
  transition: left 200ms ease-out;
  width: 350px;
  font-size: 15px;
  padding: 5px 10px 10px 10px;
  border: gray 1px solid;
}

.searchbox {
  margin: 0;
  width: 100%;
  padding: 10px 6px;
}

.searchhit {
  border: gray 1px solid;
  margin: 2px 0px;
  padding: 4px 6px;
  cursor: pointer;
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.searchhit:hover, .searchhit:focus {
  position: relative;
  top: 1px;
  left: 1px;
  background-color: #eee;
  transition: font-size 100ms linear, background-color 100ms linear;
  outline: none;
}

.searchhitgroup {
  margin-top: 15px;
  padding: 2px 5px;
  background-color: #f8f8f8;
}

.searchhitgroup.pier:before {
  font-family: FontAwesome;
  content: "\f13d";
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
}

.searchhitgroup.vessel:before {
  content: "\f21a";
  font-family: FontAwesome;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
}

.hitspecifier {
  color: gray;
  font-size: 0.7em;
}

.searchhitgroupspecifier {
  margin-left: 15px;
  color: gray;
  font-size: 0.7em;
}

.searchhitgroupcontent {
  margin-left: 25px;
}

.route .searchhitgroupcontent {
  margin-left: initial;
}

.hitroutelineouter {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding-top: 4px;
}

.hitrouteline {
  width: 30px;
  display: inline-block;
  height: 50%;
  margin-right: 10px;
}

.hitrouteinfo {
  padding-left: 7px;
  display: inline-block;
}
[type="search"] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 16px;
}

.box {
  height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: solid #ddd 1px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.box:hover {
  position: relative;
  background-color: #eee;
  font-size: 1.2em;
  transition: font-size 100ms linear, background-color 100ms linear;
}

.boxs {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  padding: 10px;
  min-height: 40px;
  border-bottom: solid #ddd 1px;
  overflow: hidden;
}

.boxs button {
  height: 30px;
  padding-top: 4px;
}

.boxs h4 {
  font-size: 18px; 
}

.MapTypeSelector {
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.mapSelectOption {
  height: 0px;
  cursor: pointer;
  margin-bottom: 0px;
  overflow: hidden;
  transition: height 300ms ease-in-out;
}

.mapSelectOption.selected {
  height: 30px;
  border: 1px solid darkgrey;
}

.mapSelectOption:hover {
  border: 3px solid darkgrey;
}

.MapTypeSelector.open .mapSelectOption {
  height: 87px;
  margin-bottom: 3px;
  transition: height 300ms ease-in-out;
}

.MapTypeSelector.open .mapSelectOption.selected {
  border: 3px solid blue;
}

.mapSelectOption .text {
  position: absolute;
  top: 2px;
  left: 5px;
  text-shadow:
  -2px -2px 2px rgba(255, 255, 255, 0.8),
  2px -2px 2px rgba(255, 255, 255, 0.8),
  -2px 2px 2px rgba(255, 255, 255, 0.8),
  2px 2px 2px rgba(255, 255, 255, 0.8),
  -2px 0px 2px rgba(255, 255, 255, 0.8),
  2px 0px 2px rgba(255, 255, 255, 0.8),
  0px -2px 2px rgba(255, 255, 255, 0.8),
  0px 2px 2px rgba(255, 255, 255, 0.8);
}

.layerselector {
  cursor: pointer;
  width: 100%;
  margin: 0px;
  padding: 5px;
}

.layerselector:hover {
  background-color: #eee;
}

.infosection .layerselector {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
} 

.layer input[type=checkbox] { display:none; }
.layer input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.layer input[type=checkbox] + label:before { 
  width: 20px;
  content: "\f096";
  letter-spacing: 10px;
}

.layer input[type=checkbox]:checked + label { 
  font-weight: bold;
}

.layer input[type=checkbox]:checked + label:before { 
  content: "\f046";
}

.layer input[type=checkbox][disabled] {
    color: #ddd;
    cursor: initial;
}
.layer input[type=checkbox][disabled] + label {
  color: #ddd; 
}

.infocontent {
  /* position: absolute;
  top: 0px; */
  max-width: 640px;
  margin: 0 auto;
  background-color: #fcfdfe;
  overflow: hidden;
  padding: 20px;
  padding-bottom: 40px;
}

.infotitle {
  font-size: 20px;
  font-weight: 600;
  min-height: 50px;
}

.infotitle .specifier {
  font-size: 16px;
  font-weight: 400;
  min-height: 10px;
}

.spaceabove {
  margin-top: 30px;
}

.contactsheader {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid lightgray;
  font-size: 20px;
  font-weight: 600;
}

.vesselbox {
  border: 0px solid;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #eee;
  padding: 3px 5px;
  height: 40px;
}

.vesselname {
  position: relative;
  top: 8px;
  left: 40px;
  font-size: 18px;
  padding-right: px;
}

.vesselname:before {
  content: "\f21a";
  font-family: FontAwesome;
  -moz-osx-font-smoothing: grayscale;
  left: -35px;
  font-size: 25px;
  position:absolute;
  top:-10px;
}

.vesselfeatures {
  padding-right: 5px;
  font-size: 12px;
  text-align: right;
  position: absolute;
  bottom: 6px;
  right: 15px;
}

.vesselfeatures span {
  margin-left: 7px;
}

.routefeature {
  padding-left: 45px;
}

.routefeature:before {
  font-family: FontAwesome;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
}

.routefeature.negative:before {
  color: #ccc;
}

.routefeature.interval:before {
  content: "\f0ec";
}

.routefeature.duration:before {
  content: "\f017";
}

.routefeature.cost:before {
  content: "\f153";
}

.routefeature.booking:before {
  content: "\f0f6";
}

.routefeature.order:before {
  content: "\f095";
}

.routefeature.seasonal:before {
  content: "\f185";
}

.routefeature.limit:before {
  content: "\f256";
}

.routefeature.seealso:before {
  content: "\f06e";
}

.timetablebutton {
  width: 100%;
  margin-top: 5px;
}

.timetablebutton:before {
  font-family: FontAwesome;
  content: "\f017";
  -moz-osx-font-smoothing: grayscale;
}

.timetablebutton.external:after, .linkbutton.external:after {
  font-family: FontAwesome;
  content: " \f08e";
  -moz-osx-font-smoothing: grayscale;
}

.pierrow:before {
  font-family: FontAwesome;
  content: "\f13d";
  font-size: 30px;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  margin-bottom: 5px;
  float: left;
}

.pierlink {
  display: inline-block;
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: 2px 4px;
  margin-right: 3px;
  margin-bottom: 3px;
  user-select: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
}

.pierlink:hover {
  background-color: #8de;
}

.info h4 {
  user-select: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
}

div.timetablelink {
  border-bottom: 2px solid black;
}

div.timetablelink:last-of-type {
  border-bottom: none;
}

.contactsbox {
  max-width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto; 
}

.contactformheader {
  font-size: 18px;
  font-weight: normal;
  margin-top: 20px;
}

.contactbutton {
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto; 
}

.contactbutton:before {
  font-family: FontAwesome;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
}

.contactbutton.phone:before {
  content: "\f095\00a0\00a0";
}

.contactbutton.email:before {
  content: "\f003\00a0\00a0";
}

.contactbutton.www:before {
  content: "\f0ac\00a0\00a0";
}

.contactbutton.facebook:before {
  color: #3B5998;
  font-size: 1.2em;
  content: "\f230\00a0\00a0";
}

.pier {

}

.mainpier .pier {
  font-weight: bold;
}

.pierspecifier {
  font-size: -2px;
  font-weight: normal;
}

h4 {
  font-size: 20px;
}

.btn:focus, .btn.active:focus {outline:0; box-shadow: none !important;} 

.btn-default.active, .btn-default.active:focus {
  background-color: #bbb;
}

.btn-default:focus {
  background-color: #fff;
  border-color: #ccc;
}

@media (hover:hover) {
  .btn:hover, .btn.active:hover { 
    background-color: #8de;
  }
}

.collapsing {
    transition: height 0.4s;
}

.center {
  text-align: center;
}

.mapoverlay {
  display: none;
  position: static;
  top: 0px;
  width: 100%; 
  height: 80%;
  background-color: transparent;
  z-index: 110;
  pointer-events: none;
}

#mapcontainer {
  position: absolute;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 100;
}

@media screen and (max-width: 767px) {
  #wrapper2.info-open .mapoverlay {
    display: block;
    pointer-events: auto;
  }

  .spaceabove {
    margin-top: 15px;
  }

  .contactformheader {
    margin-top: 10px; }
}

.headerbox {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.contentsbox {
  margin-top: 20px;
}

.contentbox {
  margin-top: 10px;
  padding: 10px 5px;
  border: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  color: black;
  text-decoration: none; 
  background-color: none;
}

.contentbox:hover {
  background-color: rgb(129, 216, 236);
;
}

.routename {
  font-weight: bold;
}

.infopageroute {
  display: inline-block;
  width: 30px;
  height: 7px;
  border-top: 4px solid #000;
  margin-right: 15px;
}

.ferrieslist {
  list-style: none;
  padding-left: 45px;
}

.ferrieslist li {
  text-indent: -45px;
}

#liveindpos {
  position: absolute;
  left: 3px;
  top: 53px;
  z-index: 101;
  overflow: visible;
}

#liveind {
  display: block;
  position: relative;
  left: -200px;
  background: rgba(0,0,0,.5);
  border-radius: 5px;
  padding: 5px;
  color: white;
  transition: left 400ms ease-in;
}

#liveind.visible {
  left: 0px;
  transition: left 800ms ease-out;
}

.location-button {
  margin-bottom: 15px;
  margin-right: 10px;
  color: #d0d0d0;
  border: none;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}

.location-button:before {
  content: "\f05b";
  font-family: FontAwesome;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  position:absolute;
  top: 5px;
  right: 7px;
}

.location-button.active {
  color: #3B84DF;
}

.location-button.active.follow {
  background-color: #aed1fc;
}

.reset-button {
  margin-bottom: 25px;
  color: #606060;
  border: none;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #fbe6a7;
  width: 60px;
  height: 40px;
}

.reset-button:before {
  content: "\f048";
  font-family: FontAwesome;
  -moz-osx-font-smoothing: grayscale;
  font-size: 26px;
  position:absolute;
  top: 5px;
  right: 21px;
}

.fmodal-enter {
  opacity: 0.01;
}

.fmodal-enter.fmodal-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fmodal-leave.fmodal-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.info {
  position: absolute;
  background-color: #fbfcfd;
  border: 1px solid grey;
  box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 110;
  pointer-events: auto;
  opacity: 1;
  transition: opacity 200ms ease-in 400ms, width 700ms ease-in-out, top 700ms ease-in-out, height 700ms ease-in-out;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.info.hidden {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
  transition-delay: 100ms;
}

@media screen and (min-width: 768px) {
  .info {
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 400px;
    overflow-y: scroll;
  }
  .info-enter {
    left: -400px;
  }
  .info-enter.info-enter-active {
    left: 0px;
    transition: left 200ms ease-out;
  }
  .info-leave.info-leave-active {
    left: -400px;
    transition: left 200ms ease-in;
  }
  .closeInfoButton.fix1 {
    position: fixed;
    top: 70px;
    left: 340px;
    box-shadow: 0 2px 2px 0 rgba(220, 220, 220, 0.5);
  }

  .info-open #searchpanel {
    left: 420px;
    transition: left 200ms ease-out;
  }  
}

@media screen and (max-width: 767px) {
  .info {
    top: 80%;
    min-height: 20%;
    width: 100%; 
    overflow-y: hidden; 
  }
  .info-enter {
    top: 100%;
  }
  .info-enter.info-enter-active {
    top: 80%;
    transition: top 200ms ease-out;
  }
  .info-leave.info-leave-active {
    top: 100%;
    transition: top 200ms ease-in;
  }
}

@media screen and (max-height: 500px) {
  #topbar {
    height: 40px;
  }
  .topbarbutton {
    font-size: 25px;
    margin: 2px;
    max-height: 36px;
  }
  #topbartitle {
    padding: 5px;
  }
  #menu, #settings, #infopage {
    top: 40px;
  }
  #searchpanel {
    top: 45px;
  }
  #wrapper2 {
    top: 40px;
  }
  #mapoverlay {
    top: 40px;
  }
}

@media screen and (max-height: 500px) and (min-width: 768px) {
  .closeInfoButton.fix1 {
    top: 60px;
  }
}

.infocontent-enter {
  opacity: 0;
}

.infocontent-enter.infocontent-enter-active {
  opacity: 1;
  transition: opacity 200ms linear;
}

.infocontent-leave {
  opacity: 1;
  pointer-events: none;
  position: relative;
  top: 0px;
}

.infocontent-leave.infocontent-leave-active {
  opacity: 0;
  transition: opacity 200ms linear;
}

#loader.loader-enter {
  opacity: 0;
}

#loader.loader-enter.loader-enter-active {
  opacity: 1;
  transition: opacity 200ms linear; 
}

#loader.loader-leave {
  opacity: 1;
}

#loader.loader-leave.loader-leave-active {
  opacity: 0;
  transition: opacity 700ms linear;
}